import React from "react";
import PropTypes from "prop-types";
import ContentListItem from "./ContentListItem";

class ContentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredList: props.contents,
      searchedList: props.contents,
      searchValue: '',
      showDropdown: false,
      dropdownOptions: [
        {value: "alpha", label: "Product Name A-Z"},
        {value: "recent", label: "Most Recent"}
      ],
      filterOption: {value: "alpha", label: "Product Name A-Z"}
    };
    this.setSearch = this.setSearch.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.setFilterOption = this.setFilterOption.bind(this);
  }

  componentDidMount() {
    this.setFilterOption({value: "alpha", label: "Product Name A-Z"});
  }

  toggleDropdown() {
    const { showDropdown } = this.state;
    this.setState({showDropdown: !showDropdown});
  }

  setFilterOption(option) {
    const { searchValue } = this.state;
    const { contents } = this.props;
    if (option.value === "alpha") {
      contents.sort((c1, c2) => {
        if (c1.title < c2.title) return -1;
        if (c1.title > c2.title) return 1;
        return 0;
      });
    } else if (option.value === "recent") {
      contents.sort((c1, c2) => {
        var d1 = Date.parse(c1.updated_at);
        var d2 = Date.parse(c2.updated_at);
        if (d1 < d2) return -1;
        if (d1 > d2) return 1;
        return 0;
      });
    }
    this.setState({ filteredList: contents, filterOption: option }, this.setSearch(searchValue));
  }

  searchChanged(e) {
    this.setState({searchValue: e.target.value}, this.setSearch(e.target.value));
  }

  setSearch(value) {
    const { filteredList } = this.state;
    const searchedList = [];
    filteredList.forEach(content => {
      if ( content.title.toLowerCase().includes(value.toLowerCase()) || value === "") {
        searchedList.push(content);
      }
    });
    this.setState({ searchedList });
  }

  renderDropdownOptions() {
    const { dropdownOptions } = this.state;
    const renderedOptions = dropdownOptions.map(option =>
      <div className="cat-list-filter-sort-by-select-dropdown-option" onClick={() => this.setFilterOption(option)}>
        {option.label}
      </div>
    );
    return renderedOptions;
  }

  renderContents() {
    const { searchedList } = this.state;
    const renderedContents = searchedList.map(content =>
      <ContentListItem content={content} />
    );
    return renderedContents;
  }

  render() {
    const { showDropdown, filterOption } = this.state;
    return (
      <div className="cat-tiles">
        <div className="row tile-row">
          <div className="cat-list-content-separator" />
          <div className="cat-list-filter-bar">
            <div className="cat-list-filter-sort-by">
              Sort by:
              <div className="cat-list-filter-sort-by-select" onClick={this.toggleDropdown}>
                {filterOption.label}
                {
                  showDropdown ?
                  <div className="cat-list-filter-sort-by-select-dropdown">
                    {this.renderDropdownOptions()}
                  </div>
                  :
                  ''
                }
              </div>
            </div>
            <div className="cat-list-filter-search">
              <input type="text" placeholder="Search" onChange={(e) => this.searchChanged(e)} />
            </div>
          </div>
        </div>
        <div className="row tile-row equal" style={{marginLeft: "-5px", marginRight: "-5px"}}>
          {this.renderContents()}
        </div>
      </div>
    );
  }
}

export default ContentList;
