import React from "react";
import PropTypes from "prop-types";
import MaterialTable from 'material-table';
import Modal from "react-modal";

class BMTableDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pickerOpen: false
    };
    this.togglePicker = this.togglePicker.bind(this);
    this.setYear = this.setYear.bind(this);
  }

  togglePicker() {
    const { pickerOpen } = this.state;
    this.setState({pickerOpen: !pickerOpen});
  }

  setYear(year) {
    const { setSelectedYear } = this.props;
    setSelectedYear(year);
  }

  renderPicker() {
    const { years } = this.props;
    const { pickerOpen } = this.state;
    if (pickerOpen) {
      const renderedYears = years.map((year, index) =>
        <div className="building-material-table-date-picker-year" onClick={() => this.setYear(year)}>
          {year}
        </div>
      );
      return (
        <div className="building-material-table-date-picker-year-wrapper">
          {renderedYears}
        </div>
      )
    }
  }

  render() {
    const { years, selectedYear } = this.props;
    const { pickerOpen } = this.state;
    if (years.length > 0) {
      return (
        <div className="building-material-table-date-picker-wrapper" onClick={this.togglePicker}>
          {selectedYear}
          <div className={`${pickerOpen ? 'building-materials-variant-item-arrow-open' : 'building-materials-variant-item-arrow'}`} />
          {this.renderPicker()}
        </div>
      );
    }
    return <></>;
  }
}

export default BMTableDatePicker;
