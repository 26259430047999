import React from 'react';
import Dropzone from "react-dropzone";
import Cookies from "universal-cookie";

class UploadDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uploads: props.uploads || [],
      uploading: false,
      error: null,
    }
    this.onDrop = this.onDrop.bind(this);
    this.deleteUpload = this.deleteUpload.bind(this);
  }

  renderUploadsTable() {
    const { root } = this.props;
    const { uploads } = this.state;
    return(
      <table
        className="table-striped"
        style={{width:"100%"}}
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Link</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {uploads.map((upload) => 
            <tr>
              <td>{upload.name}</td>
              <td>
                <a href={`${root}f/${upload.slug}`}>
                  {root}f/{upload.slug}
                </a>
              </td>
              <td> 
                <button onClick={() => this.deleteUpload(upload.id)}>Delete</button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  deleteUpload(id) {
    fetch(`/admin/uploads/${id}`, {
      method: 'DELETE',
      redirect: "manual",
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        const { uploads } = this.state;
        this.setState({
          uploads: uploads.filter(x=>x.id != id)
        })
      } else {
        alert(json.error)
      }
    });
  }

  onDrop(acceptedFiles) {
    const { uploading } = this.state;
    try {
      if (!uploading && acceptedFiles.length > 0) {
        this.setState({ uploading: true });
  
        var form_data = new FormData();
        const cookies = new Cookies();
        const token = cookies.get("X-CSRF-Token");
    
        form_data.append(`upload[file]`, acceptedFiles[0]);
    
        fetch('/admin/uploads', {
          method: 'POST',
          redirect: "manual",
          body: form_data,
          headers: {
            "X-CSRF-Token": token,
          }
        })
        .then(response => {
          return response.json();
        })
        .then(json => {
          const { uploads } = this.state;
          if (json.error == null) {
            this.setState({
              uploading: false,
              uploads: [json.upload].concat(uploads)
            })
          } else {
            alert(json.error)
          }
        });
      } else {
        this.setState({
          error: "This file type is not supported."
        });
      }
    }catch(error) {
      console.log(error)
      this.setState({
        uploading: false,
        error: "An error occured. Please try again."
      });
    }
 
  }

  renderUploadDropzone() {
    const { error, uploading } = this.state;
    return(
      <div>
        <Dropzone onDrop={this.onDrop} name="upload[file]">
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div {...getRootProps()} className="uploads-drop-section" style={{width: "auto"}}>
                <input {...getInputProps()} id="item-form-attachment-dropzone-input"/>
                <div className="dropzone-text">
                  {!uploading ? "Drag and Drop attachment here" : "Uploading..."}
                </div>
                {error ? <div>{error}</div> : null}
              </div>
            );
          }}
        </Dropzone>
      </div>
    )
  }


  render() {
    return(
      <div>
        <div className="row">
          <div className="col-xs-12">
            {this.renderUploadDropzone()}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            {this.renderUploadsTable()}
          </div>
        </div>
      </div>
    )
  }
}

export default UploadDashboard;