import React from 'react';
import HistorySection from './history/HistorySection';
import HistoryNav from './history/HistoryNav';
import HistoryMobileNav from './history/HistoryMobileNav';

class HistoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vWidth: window.innerWidth,
      vHeight: window.innerHeight,
      modalOpen: false,
    }
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange() {
    this.setState({ vWidth: window.innerWidth, vHeight: window.innerHeight });
  }

  render() {
    const {historyData} = this.props;
    const {vWidth, vHeight} = this.state;
    return (
      <div>
        {vWidth < 1200 || vHeight < 900 ? <HistoryMobileNav /> : <HistoryNav />}
        <div id="top-of-history-page">
          <h1 className="history-page-title">
            HISTORY
          </h1>
          <p className="history-page-summary">
          Founded on February 14, 1900, Granite Rock Company’s rich history parallels the growth and development of California’s central coast. With core values originally established by our founder, Arthur Roberts Wilson, our family vision and company culture has remained unwavering. 

          <p className="history-page-mission">
          <b>Building a better future for our families,<br/> our communities, and our region – since 1900.</b>
          </p>

          This Virtual Museum is a compilation of favorite photos and documents from each decade of business, chosen to illustrate the people, locales and events of the time. Our extensive collection of photographs and historic documents shares the stories of people who built an enduring business while providing for their families and contributing to their communities.
          </p>
        </div>
        {
          historyData.map((historySectionAttributes) => 
            <HistorySection 
              {...historySectionAttributes}
              showModalThumbs={vWidth < 900 || vHeight < 900 ? false : true}
            />
          )
        }
        <div id="about-history">
          <div>
            We welcome comments, corrections and additions to our archives. Construction is an essential part of our region’s history, and we are glad to be able to share our story with you.
          </div>
          <br />
          <div>
            Now, still family owned, Graniterock locations extend from Oakland to Monterey. The values of quality, innovation and respect for people which were first established by the Company’s founder, Arthur R. Wilson, continue to lead Graniterock into the future.
          </div>
        </div>
      </div>
    )
  }
}

export default HistoryPage;