import React from "react";
import PropTypes from "prop-types";

class ReactTest extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      <div className="react-test" style={{position:"absolute", top: "0", width:"100%",height:"100px", background: 'red'}}>
        React is functional
      </div>
    )
  }
}

export default ReactTest;
