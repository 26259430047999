import React from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const sections = ["top-of-history-page", "-1900", "1900", "1910", "1920", "1930", "1940", "1950", "1960", "1970", "1980", "1990", "2000", "2010", "2020"];

class HistoryMobileNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSection: "top-of-history-page"
    }
    this.handleScroll = this.handleScroll.bind(this);
    this.handleUpClick = this.handleUpClick.bind(this);
    this.handleDownClick = this.handleDownClick.bind(this);
  }
  
  componentWillMount() {
    const body = document.querySelector("body")
    body.addEventListener('scroll', this.handleScroll);
  }
  
  componentWillUnmount() {
    const body = document.querySelector("body")
    body.removeEventListener('scroll', this.handleScroll);
  }
  
  getSectionSelectors() {
    let sections = document.querySelectorAll(`div[id='top-of-history-page'], .history-section`);
    return sections;
  }
  
  handleScroll() {
    const sections = this.getSectionSelectors();
    for (let section of sections) {
      if (this.elemIsInViewport(section)) {
        this.setState({currentSection: section.id})
        break;
      }
    }
  }
  
  handleUpClick() {
    const {currentSection} = this.state;
    if (currentSection === 'top-of-history-page') {
      return;
    } else {
      const index = sections.indexOf(currentSection);
      const prevSection = document.querySelector(`div[id='${sections[index - 1]}']`);
      prevSection.scrollIntoView({behavior: 'smooth'});
    }
  }

  handleDownClick() {
    const {currentSection} = this.state;
    if (currentSection === '2020') {
      return;
    } else {
      const index = sections.indexOf(currentSection);
      const prevSection = document.querySelector(`div[id='${sections[index + 1]}']`);
      prevSection.scrollIntoView({behavior: 'smooth'});
    }
  }

  elemIsInViewport(elem) {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top + (bounding.height * 0.5) >= 0 //Checks if the top 70% of the div is anywhere in the viewport
    )
  }

  render() {

    return(
      <div className="history-nav">
        <a className="history-nav-anchor" href="#top-of-history-page"><div id="top-nav-element" className="top mobile-history-nav-element">Top</div></a>
        <div>
          <div className="mobile-history-nav-element" onClick={this.handleUpClick} style={{marginBottom: "10px"}}><KeyboardArrowUpIcon style={{fontSize: "50px"}} /></div>
          <div className="mobile-history-nav-element" onClick={this.handleDownClick}><KeyboardArrowDownIcon style={{fontSize: "50px"}} /></div>
        </div>
      </div>
    )
  }
}

export default HistoryMobileNav;