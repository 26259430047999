import React from "react";
import PropTypes from "prop-types";
import RelatedItem from "./RelatedItem";

class RelatedItems extends React.Component {
  constructor(props) {
    super(props);
    const relatedList = props.content.data.related_list !== undefined && props.content.data.related_list !== "" ? JSON.parse(props.content.data.related_list) : [];
    this.state = {
      dropdownIsOpen: true,
      checked: true,
      relatedItemsPreference: relatedList.length === 0 ? "automatic" : "manual",
      relatedProductList: relatedList
    };
    this.addRelatedProduct = this.addRelatedProduct.bind(this);
    this.deleteProductAtIndex = this.deleteProductAtIndex.bind(this);
    this.editProductAtIndex = this.editProductAtIndex.bind(this);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  buildSimpleFormRelatedProducts() {
    const { relatedProductList } = this.state;
    const relatedSF = document.getElementById("simple-form-related-products");
    relatedSF.value = JSON.stringify(relatedProductList);
  }

  toggleCheckbox(e) {
    const { checked } = this.state;
    e.stopPropagation();
    if (checked) {
      this.setState({ checked: !checked, relatedItemsPreference: "automatic", relatedProductList: [] });
    } else {
      this.setState({ checked: !checked });
    }
  }

  editProductAtIndex(index, product_id) {
    const { relatedProductList } = this.state;
    relatedProductList[index] = {product_id: product_id};
    this.setState({ relatedProductList: relatedProductList }, this.buildSimpleFormRelatedProducts());
  }

  deleteProductAtIndex(index) {
    const { relatedProductList } = this.state;
    relatedProductList.splice(index, 1);
    this.setState({ relatedProductList: relatedProductList }, this.buildSimpleFormRelatedProducts());
  }

  toggleDropdown() {
    const { dropdownIsOpen } = this.state;
    this.setState({ dropdownIsOpen: !dropdownIsOpen });
  }

  addRelatedProduct() {
    const { relatedProductList } = this.state;
    relatedProductList.push({product_id: 'default'});
    this.setState({ relatedProductList: relatedProductList});
  }

  relatedProductsChanged(e) {
    const { relatedProductList } = this.state;
    let newProductList = relatedProductList;
    if (e.target.id === "automatic") {
      newProductList = [];
    }
    this.setState({
      relatedItemsPreference: e.target.id,
      relatedProductList: newProductList
    });
  }

  renderProductList() {
    const { contents, content } = this.props;
    const { relatedProductList } = this.state;
    const renderedRelatedProducts = relatedProductList.map((product, index) =>
      <RelatedItem
        product={product}
        index={index}
        editProductAtIndex={this.editProductAtIndex}
        deleteProductAtIndex={this.deleteProductAtIndex}
        content={content}
        contents={contents}
      />
    );
    return renderedRelatedProducts;
  }

  renderRelatedProducts() {
    const { relatedItemsPreference } = this.state;
    if (relatedItemsPreference === "manual") {
      return (
        <>
          {this.renderProductList()}
          <div onClick={this.addRelatedProduct} className="building-materials-add-variant">+ Add a related product</div>
        </>
      );
    }
    return "";
  }

  renderDropdown() {
    const { dropdownIsOpen, relatedItemsPreference } = this.state;
    if (dropdownIsOpen) {
      return (
        <div className="building-materials-dropdown-wrapper" style={{marginTop: "10px"}}>
          <div>
            <input
              className="hidden-form-field"
              type="radio"
              id="automatic"
              name="related_products_radio"
              onChange={(e) => {this.relatedProductsChanged(e)}}
              checked={relatedItemsPreference === "automatic"}
            />
            <label for="automatic">Automatically select products from same category</label>
          </div>
          <div>
            <input
              className="hidden-form-field"
              type="radio"
              id="manual"
              name="related_products_radio"
              onChange={(e) => {this.relatedProductsChanged(e)}}
              checked={relatedItemsPreference === "manual"}
            />
            <label for="manual">Manually select products</label>
          </div>
          {this.renderRelatedProducts()}
        </div>
      );
    }
    return '';
  }

  render() {
    const { checked, dropdownIsOpen } = this.state;
    return (
      <div id="building-materials-related-items-wrapper">
        <div className="building-materials-dropdown-label" onClick={this.toggleDropdown}>
          <input type="checkbox" onClick={this.toggleCheckbox} checked={checked} />
          Related products
          <i class={`arrow ${dropdownIsOpen ? 'down' : 'up'}`}></i>
        </div>
        {this.renderDropdown()}
        <input
          className="hidden-form-field"
          type="hidden"
          id="simple-form-related-products"
          name="content[data][related_list]"
        />
      </div>
    );
  }
}

export default RelatedItems;
