import React from "react";
import PropTypes from "prop-types";
import RelatedItem from "./RelatedItem";

class RelatedItems extends React.Component {
  constructor(props) {
    super(props);
    let relatedItems = props.content.data.related_list ? JSON.parse(props.content.data.related_list) : [];
    this.state = {
      relatedItems: relatedItems
    };
  }

  renderThumbnail(content) {
    let imgSrc = "/assets/placeholder/coming-soon_185-185.png";
    if (content.image) {
      imgSrc = content.image;
    }
    return <img src={imgSrc} />;
  }

  renderRelatedList() {
    const { relatedItems } = this.state;
    const { contents } = this.props;
    const contentsList = [];
    contents.forEach(product => {
      relatedItems.forEach(item => {
        if ( parseInt(item.product_id) === product.id ) {
          contentsList.push(product);
        }
      });
    });

    const renderedContents = contentsList.map(content =>
      <div className="col-sm-2" style={{padding: "0px 5px"}}>
        <div className="related-item-wrapper">
          <a href={content.url}>
            <div className="related-item-image">{this.renderThumbnail(content)}</div>
          </a>
          <div className="related-item-title">{content.title}</div>
        </div>
      </div>
    );
    return renderedContents;
  }

  renderAutomaticList() {
    const { contents } = this.props;
    let renderedContents = contents.map(content =>
      <div className="col-sm-2" style={{padding: "0px 5px"}}>
        <div className="related-item-wrapper">
          <a href={content.url}>
            <div className="related-item-image">{this.renderThumbnail(content)}</div>
          </a>
          <div className="related-item-title">{content.title}</div>
        </div>
      </div>
    );
    renderedContents.length = Math.min(renderedContents.length, 6);
    return renderedContents;
;
  }

  render() {
    const { contents } = this.props;
    const { relatedItems } = this.state;
    if (relatedItems.length === 0 || contents.length === 0) {
      return <>{this.renderAutomaticList()}</>;
    }
    return (
      <>{this.renderRelatedList()}</>
    );
  }
}

export default RelatedItems;
