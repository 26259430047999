import React from "react";
import PropTypes from "prop-types";

class BranchForm extends React.Component {
  constructor(props) {
    super(props);

    const branchList = props.content.data.branches !== undefined ? JSON.parse(props.content.data.branches) : [];

    this.state = {
      dropdownIsOpen: true,
      checked: true,
      branchList: branchList
    };
    this.addOrRemoveFromBranchList = this.addOrRemoveFromBranchList.bind(this);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  toggleDropdown() {
    const { dropdownIsOpen } = this.state;
    this.setState({ dropdownIsOpen: !dropdownIsOpen });
  }

  toggleCheckbox(e) {
    const { checked } = this.state;
    e.stopPropagation();
    if (checked) {
      this.setState({ checked: !checked, branchList: [] });
    } else {
      this.setState({ checked: !checked });
    }
  }

  checkBranchListForMatch(currentBranch) {
    const { branchList } = this.state;
    let checked = false;
    branchList.forEach(branch => {
      if (branch === currentBranch) {
        checked = true;
      }
    });
    return checked;
  }

  addOrRemoveFromBranchList(currentBranch) {
    const { branchList } = this.state;
    let newBranchList = [];
    const exists = branchList.includes(currentBranch)
    if (exists) {
      newBranchList = branchList.filter((c) => {
        return c !== currentBranch;
      });
    } else {
      newBranchList = branchList;
      newBranchList.push(currentBranch);
    }
    this.setState({ branchList: newBranchList });
  }

  renderItemBranches(branches) {
    const renderedItemBranches = branches.body.map((branch, index) =>
      <span className="checkbox">
        <label htmlFor={`content_data_branches_${branches.link[index]}`}>
          <input
            type="checkbox"
            className="check_boxes optional"
            name="content[data][branches][]"
            id={`content_data_branches_${branches.link[index]}`}
            onClick={() => this.addOrRemoveFromBranchList(branches.link[index])}
            checked={this.checkBranchListForMatch(branches.link[index])}
            value={branches.link[index]}
          />
          {branch}
        </label>
      </span>
    );
    return (
      <div className="controls">
        {renderedItemBranches}
      </div>
    );
  }

  renderColumnItems(col) {
    const { branches } = this.props;
    const renderedItems = col.map(item =>
      <div className="control-group check_boxes optional content_data_branches">
        <label className="check_boxes optional control-label">{branches[item].title}</label>
        {this.renderItemBranches(branches[item])}
      </div>
    );
    return renderedItems;
  }

  renderBranchColumns() {
    const { branchesSplit } = this.props;
    const renderedColumns = branchesSplit.map(col =>
      <div className="col-md-3">
        {this.renderColumnItems(col)}
      </div>
    );
    return renderedColumns;
  }

  renderDropdown() {
    const { dropdownIsOpen } = this.state;
    if (dropdownIsOpen) {
      return (
        <div className="branches" style={{marginTop: "5px"}}>
          <div className="control-group">
            <div className="row" id="check_boxes">
              {this.renderBranchColumns()}
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    const { checked, dropdownIsOpen } = this.state;
    return (
      <div id="building-materials-dropdown-wrapper">
        <div className="building-materials-dropdown-label" onClick={this.toggleDropdown}>
          <input type="checkbox" onClick={this.toggleCheckbox} checked={checked} />
          Locations
          <i class={`arrow ${dropdownIsOpen ? 'down' : 'up'}`}></i>
        </div>
        {this.renderDropdown()}
      </div>
    );
  }
}

export default BranchForm;
