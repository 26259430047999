import React from "react";
import PropTypes from "prop-types";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

class HistoryCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carouselIndex: this.props.index
    }
    this.toggleNext = this.toggleNext.bind(this);
    this.togglePrevious = this.togglePrevious.bind(this);
  }

  toggleNext() {
    const { images } = this.props;
    const { carouselIndex } = this.state;
    let newCarouselIndex = carouselIndex + 1;
    this.setState({ carouselIndex: newCarouselIndex > images.length - 1 ? 0 : newCarouselIndex });
  }

  togglePrevious() {
    const { images } = this.props;
    const { carouselIndex } = this.state;
    let newCarouselIndex = carouselIndex - 1;
    this.setState({ carouselIndex: newCarouselIndex < 0 ? images.length - 1 : newCarouselIndex });
  }

  renderImage() {
    const { images } = this.props;
    const { carouselIndex } = this.state;
    const renderedImages = images.map((image, index) =>
      <div className={`history-carousel-image ${carouselIndex === index ? 'history-carousel-image-show' : 'history-carousel-image-hide'}`}>
        <a href={image.url}>
          <img src={image.url} />
        </a>
        <p>
          {image.text}
        </p>
      </div>
    );
    return renderedImages;
  }

  setCarouselImage(index) {
    this.setState({carouselIndex: index})
  }

  renderThumbnails() {
    const { images, showModalThumbs } = this.props;
    const { carouselIndex } = this.state;
    if (showModalThumbs) {
      return (
        <div className="history-carousel-thumbnail-container">
          {
            images.map((image, index) =>
              <div 
              style={ index === carouselIndex ? {backgroundImage: `url(${image.url})`, outline: '2px solid #F57B20'} : {backgroundImage: `url(${image.url})`}} 
              className={`history-carousel-thumbnail ${carouselIndex === index ? 'thumbnail-selected' : ''}`}
              onClick={() => this.setCarouselImage(index)}
              >
              </div>
            )
          }
        </div>
      )
    } else {
      return;
    }
  }

  render() {
    return (
      <div>
        <div className="history-carousel-wrapper">
          <NavigateBeforeIcon onClick={this.togglePrevious} style={{fontSize: "70px", cursor: "pointer"}}/>
          <div className="history-carousel-image-wrapper">
            {this.renderImage()}
          </div>
          <NavigateNextIcon onClick={this.toggleNext} style={{fontSize: "70px", cursor: "pointer"}}/>
        </div>
        {this.renderThumbnails()}
      </div>
    )
  }
}

export default HistoryCarousel;