import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import ContentShow from "./ContentShow";

class ContentListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      modalOpen: false
    };
    this.setHover = this.setHover.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  setHover(value) {
    this.setState({ hover: value });
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  }

  renderHoverState() {
    const { content } = this.props;
    const { hover } = this.state;
    if (hover) {
      return (
        <div className="bm-tile-hover-wrapper">
          <div className="bm-tile-hover">
            {content.type === "Content" ? "Quick View" : "View All"}
          </div>
        </div>
      );
    }
    return '';
  }

  renderModal() {
    const { content } = this.props;
    if (content.type === "Content") {
      return (
        <div className="row">
          <div className="col-xs-12 ">
            <ContentShow content={content.real_values} branches={[]} images={content.images} />
          </div>
        </div>
      );
    }
    return "";
  }

  render() {
    const { content } = this.props;
    const { modalOpen } = this.state;
    return (
      <>
        <div className="col-xs-4 col-md-2 cat-images-cc-building-materials" style={{paddingRight: "5px", paddingLeft: "5px", marginBottom: "10px"}}>
          {
            content.type === "Content" ?
            <a onClick={this.toggleModal} onMouseEnter={() => this.setHover(true)} onMouseLeave={() => this.setHover(false)}>
              <div className="bm-tile-image-wrapper">
                <img src={content.img} />
                {this.renderHoverState()}
              </div>
            </a>
            :
            <a href={content.link} onMouseEnter={() => this.setHover(true)} onMouseLeave={() => this.setHover(false)}>
              <div className="bm-tile-image-wrapper">
                <img src={content.img} />
                {this.renderHoverState()}
              </div>
            </a>
          }
          <a href={content.link}>
            <div className="cat-name-building-materials">{content.title}</div>
          </a>
        </div>
        <Modal
          className="default-modal"
          overlayClassName="default-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default ContentListItem;
