import React from 'react';

class HistoryNav extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const decades = ["-1900", "1900", "1910", "1920", "1930", "1940", "1950", "1960", "1970", "1980", "1990", "2000", "2010", "2020"];
    return(
      <div className="history-nav">
        <a className="history-nav-anchor" href="#top-of-history-page"><div id="top-nav-element" className="top history-nav-element">Top</div></a>
        {
          decades.map((decade) => 
            <a className="history-nav-anchor" href={"#" + decade}><div className="history-nav-element">{decade}</div></a>
          )
        }
        <a className="history-nav-anchor" href="#about-history"><div id="bottom-nav-element" className="bottom history-nav-element">About</div></a>
      </div>
    )
  }
}

export default HistoryNav;