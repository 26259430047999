import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'

export default class InternCarousel extends Component {
  render() {
    const {images} = this.props;
    return (
      <Carousel
       showThumbs={false}
       infiniteLoop={true}
       autoPlay={true}
       interval={5000}
      >
        {
          images.map(image => 
            <img src={image} className="intern-carousel-image"/>
          )
        }
      </Carousel>
    )
  }
}
