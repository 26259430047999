import React, { Component } from 'react';
import ReactModal from 'react-modal';

export default class InternTestimonial extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
    }
    this.closeModal = this.closeModal.bind(this);
    this.renderModal = this.renderModal.bind(this);
  }

  getEmploymentYears() {
    const {fullTimeStartYear} = this.props;
    const currentYear = Number(new Date().getFullYear());
    return currentYear - fullTimeStartYear;
  }

  closeModal() {
    console.log("Closing modal")
    this.setState({modalOpen: false})
  }

  renderModal() {
    const {name, title, college, internImage, internYears, collegeImage, quote} = this.props
    const employmentYears = this.getEmploymentYears();
    return (
      <ReactModal
      isOpen={true}
      className="intern-modal-content"
      overlayClassName="intern-modal-overlay"
      ariaHideApp={false}
      >
        
        <div className="intern-modal-container row">
          <div className="col-xs-12 col-sm-4 modal-intern-left"> 
            <img src={internImage} className='modal-intern-image'/>
            <div className='modal-intern-info'>
              <span style={{fontSize: "22px", lineHeight: "28px"}}>{name}</span>
              <br />
              {title}
              <br />
              <br />
              <div className='modal-intern-subheader'>COLLEGE</div>
              {college}
              <br />
              <br />
              <div className='modal-intern-subheader'>INTERN YEARS</div>
              {internYears}
              <br />
              <br />
              <div className='modal-intern-subheader'>FULL TIME</div>
              {employmentYears + ` year${employmentYears > 1 ? "s" : ""}`}
            </div>
          </div>
          <div className="col-xs-12 col-sm-8 modal-intern-right">
            
            <img src={collegeImage} className='intern-college-image'/>
            <br />
            "{quote}"
          </div>
          <div className="intern-close-modal">
            <div onClick={this.closeModal}>X</div>
          </div>
        </div>
        
      </ReactModal>
    )
  }
  
  render() {
    const {modalOpen} = this.state;
    return (
      <>
        <div className='col-sm-3 former-intern' onClick={() => this.setState({modalOpen: true})}>
          <img src={this.props.internImage} className='intern-image'/>
          <br />
          <span style={{fontSize: "16px"}}>{this.props.name}</span>
          <br />
          {this.props.title}
        </div>
        {modalOpen && this.renderModal()}
      </>
    )
  }
}
