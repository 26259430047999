import React from "react";
import PropTypes from "prop-types";

class RelatedItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.deleteProduct = this.deleteProduct.bind(this);
    this.editProduct = this.editProduct.bind(this);
  }

  editProduct(e) {
    const { editProductAtIndex, index } = this.props;
    editProductAtIndex(index, e.target.value);
  }

  deleteProduct() {
    const { deleteProductAtIndex, index } = this.props;
    deleteProductAtIndex(index);
  }

  renderOptions() {
    const { contents, content, product } = this.props;
    const renderedOptions = [<option value="default">Select a product</option>];
    contents.forEach(item => {
      if ( item.id !== content.id ) {
        renderedOptions.push(<option value={item.id}>{item.title}</option>);
      }
    });
    return renderedOptions;
  }

  render() {
    const { product } = this.props;
    return (
      <div className="building-materials-variant-item">
        <select name="products" id="building-materials-products" onChange={(e) => this.editProduct(e)} value={product.product_id}>
          {this.renderOptions()}
        </select>
        <div className="building-materials-variant-item-delete" onClick={this.deleteProduct}>Delete</div>
      </div>
    );
  }
}

export default RelatedItem;
