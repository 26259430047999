import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import Modal from "react-modal";
import { dataURItoBlob } from "@utility/FileUtils";
import Cropper from "react-cropper";
import Cookies from "universal-cookie";

import ProgressBar from "@ramonak/react-progress-bar";

class DropzoneComponent extends React.Component {
  constructor(props) {
    super(props);
    this._locked = false;
    this.state = {
      dropzoneText: "",
      file: '',
      fileDropped: false,
      imagePreviewUrl: this.props.setImage,
      openModal: false,
      cropResult: '',
      uploading: false,
      finishedCount: 0,
      totalCount: 0
    };
    this.onDrop = this.onDrop.bind(this);
    this.incrementUpload = this.incrementUpload.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement("body");
  }

  formUrl() {
    return "/tiles";
  }

  checkBatchComplete(totalFiles, filesProcessed) {
    if (totalFiles === filesProcessed) {
      setTimeout(() => {
        this.setState({
          uploading: false
        });
        location.reload();
      }, 1000);
    }
  }

  incrementUpload(totalFiles) {
    this.setState({
      finishedCount: this.state.finishedCount + 1
    }, () => {
      this.checkBatchComplete(totalFiles, this.state.finishedCount);
      this._locked = false;
    });
  }

  fileUpload(name, blob, totalFiles) {
    const { ableTowrite } = this.state;

    if (!this._locked) {
      this._locked = true;
      this.sendFiles(name, blob, totalFiles);
    } else {
      setTimeout(() => {
        this.fileUpload(name, blob, totalFiles);
      }, 2000);
    }
  }

  sendFiles(name, blob, totalFiles) {
    if(this.props.dontFetch) {
      return;
    }
    var form_data = new FormData();
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    let files = {}
    blob.forEach((file, index) => {
      form_data.append(`raw_image_${index}`, file);
    });

    fetch('./add_image_dropzone', {
      method: 'POST',
      redirect: "manual",
      body: form_data,
      headers: {
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        this.incrementUpload(totalFiles);
      } else {
        console.log(json.error);
      }
    });
  }

  onDrop(acceptedFiles) {
    if (acceptedFiles.length > 0) {
      this.setState({ uploadCounter: acceptedFiles.length });
      let reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onloadend = () => {
        this.setState({
          dropzoneText: "File attached",
          file: acceptedFiles[0],
          fileDropped: true,
          imagePreviewUrl: reader.result,
          uploading: true,
          totalCount: acceptedFiles.length
        }, () => {
          acceptedFiles.forEach(acceptedFile => {
            this.fileUpload('image', [acceptedFile], acceptedFiles.length);
          });
        });
      }
    } else {
      this.setState({
        dropzoneText: "This file type is not supported."
      });
    }
  }

  setDropzoneBeforeDrop() {
    const { setImage, profile_image } = this.props;
    const { dropzoneText, imagePreviewUrl } = this.state;
    return (
      <div className="dropzone-text">
        Drag and Drop photos or attachment here
      </div>
    );
  }

  renderUploadModal() {
    const { totalCount, finishedCount } = this.state;
    let percentComplete = Math.trunc(finishedCount / totalCount * 100);
    return (
        <div className="sg-modal-cropper">
          <h4>Uploading and cropping images, please wait ...</h4>
          <div className="clear-div" />
          <ProgressBar completed={percentComplete} bgColor="#F57B20" />
          <div className="clear-div" />
        </div>
    );
  }

  render() {
    const { showPreviews, fullWidth, setImage } = this.props;
    const { imagePreviewUrl, cropResult, file, fileDropped, dropzoneText, openModal, uploading } = this.state;
    return (
      <>
        <Dropzone onDrop={this.onDrop} name="hero_image[image]">
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div {...getRootProps()} className="drop-section-tiles" style={{width: "auto"}}>
                <input {...getInputProps()} id="item-form-attachment-dropzone-input"/>
                {this.setDropzoneBeforeDrop()}
              </div>
            );
          }}
        </Dropzone>
        <Modal
          className="sg-modal-cropper-wrapper"
          overlayClassName="video-modal-overlay"
          isOpen={uploading}
          contentLabel="View Image"
        >
          {this.renderUploadModal()}
        </Modal>
      </>
    );
   }
}

export default DropzoneComponent;
