import React from 'react';

import ReactModal from 'react-modal';
import HistoryCarousel from '../carousels/HistoryCarousel';
import CloseIcon from '@mui/icons-material/Close';


class HistorySection extends React.Component {
  //props: section title(decade), section text, json with images and texts to match them, background-color?, title image
  constructor(props) {
    super(props);
    this.state = {
      modalIsShowing: false,
      pictureSelectedIndex: 0,
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.renderModal = this.renderModal.bind(this);
  }

  toggleModal(index = -1) {
    const {modalIsShowing} = this.state;
    this.setState({modalIsShowing: !modalIsShowing, pictureSelectedIndex: index})
  }

  renderModal() {
    const {modalIsShowing, pictureSelectedIndex} = this.state;
    const {title, images} = this.props;
    if (modalIsShowing) {
      return(
        <ReactModal
          isOpen={modalIsShowing}
          className="history-modal-content"
          overlayClassName="history-modal-overlay"
          ariaHideApp={false}
        >
          <CloseIcon onClick={this.toggleModal} style={{marginLeft: "20px", marginTop: "20px", cursor: "pointer"}}/>
          <h1 className="history-modal-header">{title}</h1>
          <HistoryCarousel showModalThumbs={this.props.showModalThumbs} title={title} images={images} index={pictureSelectedIndex}/>
        </ReactModal>
      )
    }
  }

  render() {
    const {title, id, summary, heroImgSrc, sectionColor, images} = this.props;
    const {modalIsShowing} = this.state;
    return(
      <div id={id} className="history-section" style={{backgroundColor: sectionColor}}>
        <div className="history-section-hero" style={{backgroundImage: `url(${heroImgSrc})`}}>
          <h1 className="history-section-title">
           {title}
          </h1>
        </div>
        <p className="history-section-summary" style={{color: sectionColor == "#003F2D" ? "white" : "black"}}>
          {summary}
        </p>
        <div className="history-section-thumbnail-container">
          {images.slice(0, 6).map((img, index) => {
            return (
              <img 
                className="history-section-thumbnail"
                src={img.url}
                onClick={() => this.toggleModal(index)} 
              />
            )
          })}
        </div>
        {modalIsShowing && this.renderModal()}
      </div>
    )
  }
}

export default HistorySection;