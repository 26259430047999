import React from "react";
import PropTypes from "prop-types";
import MaterialTable from 'material-table';
import Modal from "react-modal";

class BMModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  }

  renderModal() {
    const { row } = this.props
    return (
      <div className="row">
        <div className="col-xs-12 ">
          <div className="default-modal-title">
            Quote Request
          </div>
          <div className="default-modal-description">
            <div className="default-modal-description-list">
              <div className="default-modal-description-list-name">Name:</div>
              <div className="default-modal-description-list-value">{`${row.name_first} ${row.name_last}`}</div>
            </div>
            <div className="default-modal-description-list">
              <div className="default-modal-description-list-name">Email:</div>
              <div className="default-modal-description-list-value">{`${row.email}`}</div>
            </div>
            <div className="default-modal-description-list">
              <div className="default-modal-description-list-name">Company:</div>
              <div className="default-modal-description-list-value">{`${row.company}`}</div>
            </div>
            <div className="default-modal-description-list">
              <div className="default-modal-description-list-name">Phone:</div>
              <div className="default-modal-description-list-value">{`${row.phone}`}</div>
            </div>
            <div className="default-modal-description-list">
              <div className="default-modal-description-list-name">Location:</div>
              <div className="default-modal-description-list-value">{`${row.job_address}`}</div>
            </div>
            <div className="default-modal-description-list">
              <div className="default-modal-description-list-name">I am:</div>
              <div className="default-modal-description-list-value">{`${row.role}`}</div>
            </div>
            <div className="default-modal-description-list">
              <div className="default-modal-description-list-name">Questions/Comments:</div>
              <div className="default-modal-description-list-value">{`${row.comments}`}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { modalOpen } = this.state;
    return (
      <>
        <div onClick={this.toggleModal} className="material-table-cell material-table-clickable">
          View
        </div>
        <Modal
          className="default-modal"
          overlayClassName="default-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default BMModal;
