import React from "react";
import PropTypes from "prop-types";
import VariantItem from "./VariantItem";

class VariantForm extends React.Component {
  constructor(props) {
    super(props);
    const variantType = props.content.data.variant_type !== undefined ? props.content.data.variant_type : "";
    const variantList = props.content.data.variant_list !== undefined && props.content.data.variant_list !== "" ? JSON.parse(props.content.data.variant_list) : [];

    this.state = {
      dropdownIsOpen: variantList === [] ? false : true,
      checked: variantList === [] ? false : true,
      variantType: variantType,
      variantList: variantList
    };
    this.addVariant = this.addVariant.bind(this);
    this.deleteVariantAtIndex = this.deleteVariantAtIndex.bind(this);
    this.editVariantAtIndex = this.editVariantAtIndex.bind(this);
    this.editVariantType = this.editVariantType.bind(this);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  componentDidMount() {
    const { variantList } = this.state;
    if ( variantList !== [] ) {
      this.buildSimpleFormVariants();
    }
  }

  toggleCheckbox(e) {
    const { checked } = this.state;
    e.stopPropagation();
    if (checked) {
      this.setState({ checked: !checked, variantType: "", variantList: [] });
    } else {
      this.setState({ checked: !checked });
    }
  }

  addVariant() {
    const { variantList } = this.state;
    variantList.push({name: "", photo: ""});
    this.setState({ variantList: variantList});
  }

  editVariantType(e) {
    this.setState({ variantType: e.target.value });
  }

  buildSimpleFormVariants() {
    const { variantList } = this.state;
    const variantsSF = document.getElementById("simple-form-variants");
    variantsSF.value = JSON.stringify(variantList);
  }

  toggleDropdown() {
    const { dropdownIsOpen } = this.state;
    this.setState({ dropdownIsOpen: !dropdownIsOpen });
  }

  editVariantAtIndex(index, variant) {
    const { variantList } = this.state;
    variantList[index] = variant;
    this.setState({ variantList: variantList }, this.buildSimpleFormVariants());
  }

  deleteVariantAtIndex(index) {
    const { variantList } = this.state;
    variantList.splice(index, 1);
    this.setState({ variantList: variantList }, this.buildSimpleFormVariants());
  }

  renderVariants() {
    const { images, placeholderImage } = this.props;
    const { variantList } = this.state;
    const renderedVariants = variantList.map((variant, index) =>
      <VariantItem
        variant={variant}
        index={index}
        editVariantAtIndex={this.editVariantAtIndex}
        deleteVariantAtIndex={this.deleteVariantAtIndex}
        images={images}
        placeholderImage={placeholderImage}
      />
    );
    return renderedVariants;
  }

  renderDropdown() {
    const { dropdownIsOpen, variantType } = this.state;
    if (dropdownIsOpen) {
      return (
        <div className="building-materials-dropdown-wrapper">
          <div className="p1">Variant Type</div>
          <input
            type="text"
            className="building-materials-dropdown-variant-type"
            name="content[data][variant_type]"
            value={variantType}
            onChange={(e) => {this.editVariantType(e)}}
            placeholder="ex: style, color, cut"
          />
          {this.renderVariants()}
          <div className="building-materials-add-variant" onClick={this.addVariant}>+ Add a variant</div>
        </div>
      );
    }
    return '';
  }

  render() {
    const { checked, dropdownIsOpen } = this.state;
    return (
      <div id="building-materials-dropdown-wrapper">
        <div className="building-materials-dropdown-label" onClick={this.toggleDropdown}>
          <input type="checkbox" onClick={this.toggleCheckbox} checked={checked} />
          Add Variants
          <i className={`arrow ${dropdownIsOpen ? 'down' : 'up'}`}></i>
        </div>
        {this.renderDropdown()}
        <input
          className="hidden-form-field"
          type="hidden"
          id="simple-form-variants"
          name="content[data][variant_list]"
          onChange={(e) => {this.editVariantType(e)}}
        />
      </div>
    )
  }
}

export default VariantForm;
