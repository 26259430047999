import React from "react";
import PropTypes from "prop-types";

class CategoryContentsShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMore: false,
      subContentsCutoff: 4
    };
    this.toggleViewMore = this.toggleViewMore.bind(this);
  }

  toggleViewMore() {
    const { viewMore } = this.state;
    this.setState({ viewMore: !viewMore });
  }

  renderSubContents() {
    const { link, sub_contents } = this.props;
    const { viewMore, subContentsCutoff } = this.state;
    const renderedSubContents = [];
    sub_contents.forEach((content, index) => {
      if (viewMore) {
        renderedSubContents.push(
          <div className="cat-images-content">
            <a href={content.link}>
              {content.title}
            </a>
          </div>
        );
      } else {
        if ( index < subContentsCutoff) {
          renderedSubContents.push(
            <div className="cat-images-content">
              <a href={content.link}>
                {content.title}
              </a>
            </div>
          );
        }
      }
    });
    if (sub_contents.length > subContentsCutoff) {
      renderedSubContents.push(
        <a>
          <div className="cat-images-content-view-more" onClick={this.toggleViewMore}>{viewMore ? "View Less -" : "View More +"}</div>
        </a>
      );
    }
    return renderedSubContents;
  }

  render() {
    return (
      this.renderSubContents()
    );
  }
}

export default CategoryContentsShow;
