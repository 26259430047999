import React from "react";
import PropTypes from "prop-types";

class ContentShow extends React.Component {
  constructor(props) {
    super(props);
    let variants = props.content.data.variant_list ? JSON.parse(props.content.data.variant_list) : [{}];
    this.state = {
      selectedImageId: props.images[0] ? props.images[0].id : 0,
      variants: variants,
      selectedVariant: variants[0],
      variantDropdownIsOpen: false,
      orderedBranches: []
    };
    this.toggleVariantDropdown = this.toggleVariantDropdown.bind(this);
  }

  componentDidMount() {
    const { branches, content } = this.props;
    const { selectedImageId } = this.state;
    let variants = content.data.variant_list ? JSON.parse(content.data.variant_list) : [{}];
    let orderedBranches = []
    let selectedVariant = variants[0];
    branches.forEach(branchArray => {
      branchArray.forEach(branch => {
        if (orderedBranches[branch.region] === undefined) {
          orderedBranches[branch.region] = [branch];
        } else {
          orderedBranches[branch.region].push(branch);
        }
      });
    });
    variants.forEach(variant => {
      if (variant.photo === selectedImageId) {
        selectedVariant = variant;
      }
    });
    this.setState({
      orderedBranches: orderedBranches,
      selectedVariant: selectedVariant
    });
  }

  toggleVariantDropdown() {
    const { variantDropdownIsOpen } = this.state;
    this.setState({ variantDropdownIsOpen: !variantDropdownIsOpen });
  }

  setSelectedVariant(variant) {
    this.setState({
      selectedVariant: variant,
      selectedImageId: variant.photo
    });
  }

  getImageUrl() {
    const { images } = this.props;
    const { selectedImageId, selectedVariant } = this.state;
    let url = '';
    images.forEach(image => {
      if (image.id === selectedImageId) {
        url = image.image_large;
      }
    });
    return url;
  }

  setImageId(id) {
    const { variants, selectedVariant } = this.state;
    let newVariant = selectedVariant;
    variants.forEach(variant => {
      if (variant.photo === id) {
        newVariant = variant;
      }
    });
    this.setState({
      selectedImageId: id,
      selectedVariant: newVariant
    });
  }

  renderImages() {
    const { images } = this.props;
    const { selectedImageId } = this.state;
    const renderedImages = images.map(image =>
      <div className="grid-item col-xs-2 col-sm-2">
        <div className={`image-contain ${selectedImageId === image.id ? 'image-selected' : ''}`} onClick={() => this.setImageId(image.id)}>
          <img src={image.image_large} />
        </div>
      </div>
    );
    return (
      <div className="row">
        {renderedImages}
      </div>
    );
  }

  renderVariantDropdown() {
    const { selectedVariant, variants, variantDropdownIsOpen } = this.state;
    if ( variantDropdownIsOpen ) {
      const renderedDropdownItems = variants.map(variant =>
        <div className="building-materials-variant-dropdown-item" onClick={() => this.setSelectedVariant(variant)}>
          <div className="building-materials-variant-dropdown-item-preview">{this.renderPhoto(variant.photo)}</div>
          <div className="building-materials-variant-dropdown-item-name">{variant.name}</div>
        </div>
      );
      return (
        <div className="building-materials-variant-dropdown">
          {renderedDropdownItems}
        </div>
      );
    }
    return '';
  }

  renderPhoto(id) {
    const { images } = this.props;
    let url = '';
    images.forEach(image => {
      if ( image.id === id ) {
        url = image.image_large;
      }
    });
    return <img src={url} />;
  }

  renderVariants() {
    const { content } = this.props;
    const { selectedVariant, variantDropdownIsOpen } = this.state;
    if (content.data.variant_type && content.data.variant_list) {
      return (
        <div className="building-materials-variants">
          <div className="building-materials-variant-type" style={{marginBottom: "10px"}}>{content.data.variant_type}:</div>
          <div className="building-materials-variant-list" onClick={this.toggleVariantDropdown}>
            <div className="building-materials-variant-item-preview">{this.renderPhoto(selectedVariant.photo)}</div>
            <div className="building-materials-variant-item-name">{selectedVariant.name}</div>
            <div className={`${variantDropdownIsOpen ? 'building-materials-variant-item-arrow-open' : 'building-materials-variant-item-arrow'}`} />
            {this.renderVariantDropdown()}
          </div>
        </div>
      );
    }
    return '';
  }

  renderQuoteRequest() {
    const { content } = this.props;
    return (
      <div className="building-material-quote-request">
        <div className="building-material-quote-description">
          Use our <span><a href="/calculator">materials calculator</a></span> to estimate how much will you need.
        </div>
        {this.renderQuickcreteCalculator()}
      </div>
    );
  }

  renderQuickcreteCalculator() {
    const { content } = this.props;
    if(content.data.quikcrete_calculator_link=="1") {
      return (
        <div className="building-material-quote-description">
          <br/>
          Or use  <span><a href="https://www.quikrete.com/calculator/main.asp">Quikrete's concrete calculator</a></span>.
        </div>
      )
    }
  }

  renderBranches() {
    const { branches } = this.props;
    const { orderedBranches } = this.state;
    const renderedBranches = [];
    if (Object.entries(orderedBranches).length === 0) {
      return '';
    }
    Object.entries(orderedBranches).forEach(branchArray => {
      const renderedBranch = [];
      branchArray[1].forEach(branch => {
        renderedBranch.push(
          <div className="building-materials-branch-outer">
            <div className="building-materials-branch-name" dangerouslySetInnerHTML={{ __html: branch.location }} />
          </div>
        );
      });
      renderedBranches.push(
        <div className="building-materials-branch-wrapper">
          {branchArray[0]}
          {renderedBranch}
        </div>
      );
    });

    return (
      <div className="building-materials-branches">
        <div className="building-materials-branches-title">Branch Availability - Check your local branch for availability</div>
        {renderedBranches}
      </div>
    );
  }

  render() {
    const { images, content } = this.props;
    console.log(this.props);
    if (images.length === 0) {
      return "";
    }
    return (
      <div className="row building-materials-content-wrapper">
        <div className="tile-grid">
          <div className="row">
            <div className="grid-item col-sm-6 building-materials-image-show">
              <div>
                <div className="image-contain left-image">
                  <img src={this.getImageUrl()} className='img-responsive' alt="\ " />
                </div>
              </div>
              <div className="header-tile-subhero">
                {this.renderImages()}
              </div>
            </div>
            <div className="grid-item col-sm-6 building-materials-content">
              <div className="building-materials-title">{content.title}</div>
              <div className="building-materials-description" dangerouslySetInnerHTML={{__html: content.data["description"]}} />
              {this.renderVariants()}
              {this.renderQuoteRequest()}
              {this.renderBranches()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContentShow;
