import React from "react";
import PropTypes from "prop-types";
import CategoryContentsShow from "./CategoryContentsShow";

class CategoryShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contentList: []
    };
  }

  componentDidMount() {
    const { contents } = this.props;
    let contentList = contents;
    let sortOrder = ['Pavers', 'Natural Stone', 'Landscape Supply', 'Masonry', 'Additional Products'];
    contentList.sort((a, b) => {
      if (sortOrder.indexOf(a.name) === -1) {
        return 1;
      }
      if (sortOrder.indexOf(b.name) === -1) {
        return 1;
      }
      return sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name);
    });

    this.setState({contentList: contentList});
  }

  renderImageUrl(url) {
    const { placeholder_image } = this.props;
    if (url.startsWith("placeholder")) {
      return placeholder_image;
    }
    return url;
  }

  renderContent() {
    const { contentList } = this.state;
    const renderedContents = contentList.map(content =>
      <div className="cat-images-md-5 cat-images-cc">
        <a href={content.link}>
          <img src={this.renderImageUrl(content.image)} />
        </a>
        <span className="cat-name">{content.name}</span>
        <CategoryContentsShow link={content.link} sub_contents={content.sub_contents.concat(content.sub_categories)} />
      </div>
    );
    return renderedContents;
  }

  render() {
    return (
      <>{this.renderContent()}</>
    );
  }
}

export default CategoryShow;
