import React from "react";
import PropTypes from "prop-types";

class CategoryShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contentList: []
    };
  }

  componentDidMount() {
    const { contents } = this.props;
    let contentList = contents;
    let sortOrder = ['Pavers', 'Natural Stone', 'Landscape Supply', 'Masonry', 'Additional Products'];
    contentList.sort((a, b) => {
      if (sortOrder.indexOf(a.name) === -1) {
        return 1;
      }
      if (sortOrder.indexOf(b.name) === -1) {
        return 1;
      }
      return sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name);
    });

    this.setState({contentList: contentList});
  }

  renderContent() {
    const { contentList } = this.state;
    const renderedContents = contentList.map(content =>
      <div className="building-materials-page-info-sub-title">
        <a href={content.link}>
          {content.name}
        </a>
      </div>
    );
    return renderedContents;
  }

  render() {
    return (
      <>{this.renderContent()}</>
    );
  }
}

export default CategoryShow;
