import React from "react";
import PropTypes from "prop-types";

class VariantItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photoSelectOpen: false
    };
    this.deleteVariant = this.deleteVariant.bind(this);
    this.editName = this.editName.bind(this);
    this.editPhoto = this.editPhoto.bind(this);
    this.togglePhotoSelect = this.togglePhotoSelect.bind(this);
  }

  editName(e) {
    const { variant } = this.props;
    variant.name = e.target.value;
    this.editVariant(variant);
  }

  editPhoto(id) {
    const { variant } = this.props;
    variant.photo = id;
    this.editVariant(variant);
  }

  editVariant(newVariant) {
    const { editVariantAtIndex, index } = this.props;
    editVariantAtIndex(index, newVariant);
    this.togglePhotoSelect();
  }

  deleteVariant() {
    const { deleteVariantAtIndex, index } = this.props;
    deleteVariantAtIndex(index);
  }

  togglePhotoSelect() {
    const { photoSelectOpen } = this.state;
    this.setState({ photoSelectOpen: !photoSelectOpen });
  }

  renderImageDropdown() {
    const { images } = this.props;
    const { photoSelectOpen } = this.state;
    const imageOptions = images.map(image =>
      <div className="variant-photo-select-dropdown-inner" onClick={() => this.editPhoto(image.id)}>
        <img src={image.thumbnail}/>
        <div>{image.name}</div>
      </div>
    );
    return (
      <div className="variant-photo-select">
        <div className="variant-photo-select-label" onClick={this.togglePhotoSelect}>Select Photo</div>
        {
          photoSelectOpen ?
          <div className="variant-photo-select-dropdown">
            {imageOptions}
          </div>
          :
          ""
        }
      </div>
    );
  }

  renderThumbnail() {
    const { variant, images, placeholderImage} = this.props;
    if (variant.photo) {
      let imgSrc = placeholderImage;
      images.forEach(image => {
        if (image.id === variant.photo) {
          imgSrc = image.thumbnail;
        }
      });
      return imgSrc;
    }
    return placeholderImage;
  }

  render() {
    const { name, photo } = this.state;
    const { variant } = this.props;
    return (
      <div className="building-materials-variant-item">
        <div className="building-materials-variant-item-thumbnail" style={{backgroundImage: `url(${this.renderThumbnail()})`}}></div>
        <input type="text" placeholder="Name" value={variant.name} onChange={(e) => this.editName(e)} />
        {this.renderImageDropdown()}
        <div className="building-materials-variant-item-delete" onClick={this.deleteVariant}>Delete</div>
      </div>
    )
  }
}

export default VariantItem;
