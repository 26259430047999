import React from "react";
import PropTypes from "prop-types";
import MaterialTable from 'material-table';
import { DateTime } from "luxon";
import Cookies from "universal-cookie";
import BMModal from "./BMModal";
import BMTableDatePicker from "./BMTableDatePicker";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { forwardRef } from 'react';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

class BMTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      requests: props.quote_requests,
      selectedYear: '2022'
    }
    this.setSelectedYear = this.setSelectedYear.bind(this);
  }

  setSelectedYear(year) {
    this.setState({
      selectedYear: year,
      requests: this.getRequestsByYear(year)
    });
  }

  getRequestsByYear(year) {
    const { quote_requests } = this.props;
    const newRequests = [];
    quote_requests.forEach(req => {
      if (DateTime.fromISO(req.created_at, { zone: "utc"}).toLocal().toFormat('yyyy') === year) {
        newRequests.push(req);
      }
    });
    return newRequests;
  }

  getClaimRequestUrl(id, claimed) {
    if (claimed) {
      return `/admin/building_material_requests/${id}/unclaim_request`;
    }
    return `/admin/building_material_requests/${id}/claim_request`;
  }

  claimRequest(id, claimed) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(this.getClaimRequestUrl(id, claimed), {
      method: 'POST',
      redirect: "manual",
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        console.log('success');
        location.reload();
      } else {
        console.log(json.error);
      }
    });
  }

  renderDate(row) {
    let datetime = DateTime.fromISO(row.created_at, { zone: "utc"}).toLocal();
    return (
      <div className="material-table-cell">{datetime.toFormat('LL-dd-yy')}</div>
    );
  }

  renderAction(row) {
    return (
      <BMModal row={row} />
    );
  }

  renderClaimed(row) {
    if (row.claimed) {
      return (
        <input type="checkbox" checked={true} onClick={() => this.claimRequest(row.id, row.claimed)} />
      );
    }
    return <input type="checkbox" checked={false} onClick={() => this.claimRequest(row.id, row.claimed)} />
  }

  renderClaimedBy(row) {
    return row.claimed_by
  }

  getColumns() {
    return ([
      { title: "ID", field: "id", render: (row) => <div className="material-table-cell material-table-clickable">{row.id}</div> },
      {
        title: "Date Received",
        field: "updated_at",
        render: (row) => this.renderDate(row),
        cellStyle: {
          minWidth: 140,
          maxWidth: 140
        },
      },
      { title: "First", field: "name_first", render: (row) => <div className="material-table-cell">{row.name_first}</div> },
      { title: "Last", field: "name_last", render: (row) => <div className="material-table-cell">{row.name_last}</div>},
      { title: "Company", field: "company", render: (row) => <div className="material-table-cell">{row.company}</div>},
      { title: "Email", field: "email", render: (row) => <a href={`mailto:${row.email}`} className="material-table-cell material-table-clickable">{row.email}</a>},
      { title: "Action", field: "", render: (row) => this.renderAction(row)},
      {
        title: "I Got This",
        field: "",
        cellStyle: {
          minWidth: 120,
          maxWidth: 120
        },
        render: (row) => this.renderClaimed(row)
      },
      { title: "By", field: "", render: (row) => <div className="material-table-cell material-table-overflow">{row.claimed_by}</div>}
    ]);
  }

  render() {
    const { quote_requests } = this.props;
    const { requests, selectedYear } = this.state;
    const columns = this.getColumns();
    const years = quote_requests.map(req => DateTime.fromISO(req.created_at, { zone: "utc"}).toLocal().toFormat('yyyy') );
    const yearsUniq = [...new Set(years)];

    return (
      <div id="building-material-table">
        <MaterialTable
          title={<BMTableDatePicker years={yearsUniq} selectedYear={selectedYear} setSelectedYear={this.setSelectedYear} />}
          icons={tableIcons}
          data={requests}
          columns={columns}
          options={{
            selectableRows: 'none',
            search: false,
          }}
          components={{
            Toolbar: props => (<BMTableDatePicker years={yearsUniq} selectedYear={selectedYear} setSelectedYear={this.setSelectedYear} />)
          }}
        />
      </div>
    );
  }
}

export default BMTable;
